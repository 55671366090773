























































































































































































































































import Vue from 'vue'
import TrendsVideo from '../components/TrendsVideo.vue'
import { DB } from '@/firebase/db'
import axios from 'axios'
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, limit, limitToLast, startAfter, endBefore, DocumentReference, QuerySnapshot } from "firebase/firestore"
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('Etc/GMT')

const client = algoliasearch('ITORICHELA', 'f8d7df5054de9039dc211309cf0544c1')
const indexAccount = client.initIndex('account')

export default Vue.extend({
  name: 'Home',

  components: {
    TrendsVideo,
  },

  data: () => ({
    accounts: [] as any,
    dailyTrendsArray: [] as any,
    weeklyTrendsArray: [] as any,
    monthlyTrendsArray: [] as any,
    currentVisible: {} as any,
    lastVisible: {} as any,
    currentPage: 1,
    searchWord: '',
    videoUrls: [] as any,
    searchClient: algoliasearch(
      'ITORICHELA',
      'f8d7df5054de9039dc211309cf0544c1'
    ),
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
    icons: [
      {
        label: '踊ってみた',
        icon: 'mdi-human-female-dance',
        id: '5JGCP8RdGSOdszZBt54n'
      },
      {
        label: '歌ってみた',
        icon: 'mdi-microphone-variant',
        id: 'KOUJg4MSoBTmBAlKZkSm'
      },
      {
        label: 'ドラマ',
        icon: 'mdi-video-vintage',
        id: 'xHu3ApXaGjSMZUbUKRJm'
      },
      {
        label: 'ペット',
        icon: 'mdi-paw',
        id: '2S7E27qJ2UKlsDTb71Xd'
      },
      {
        label: '食べ物',
        icon: 'mdi-food',
        id: 'L2h7OnNx3Ih3Yf6ayKhh'
      },
      {
        label: 'メイク',
        icon: 'mdi-lipstick',
        id: '5qlOK4prMN8I7fYHxcfk'
      },
      {
        label: 'スポーツ',
        icon: 'mdi-soccer',
        id: 'mr1ttMgXT6evFlYS3WbC'
      },
      {
        label: 'VTuber',
        icon: 'mdi-virtual-reality',
        id: '0UStkndMe87xgfbiNDUV'
      },
      {
        label: 'ゲーム',
        icon: 'mdi-google-controller',
        id: '7pvhXlzSpAitKaFVRtSZ'
      },
      {
        label: 'YouTuber',
        icon: 'mdi-youtube',
        id: 'NTNXADQ3FfGUCRGZU28U'
      },
      {
        label: 'スクールライフ',
        icon: 'mdi-school',
        id: 'QmfwtqmHJJx5Rh0odskX'
      },
      {
        label: 'Vlog, 日常',
        icon: 'mdi-video-account',
        id: 'I7JozCbIc4TRfxqIWesv'
      },
    ],
    items: [
      {
        color: '#1F7087',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Supermodel',
        artist: 'Foster the People',
      },
      {
        color: '#952175',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'Halcyon Days',
        artist: 'Ellie Goulding',
      },
    ],
  }),

  computed: {
    convertTimeformat: function () {
      return (time: number) => {
        // const sec = Math.floor(time/1000) % 60
        // const min = Math.floor(time/1000/60) % 60
        const hours = Math.floor(time/1000/60/60)
        const formatTime = `${hours.toLocaleString('ja-JP')}時間`
        return formatTime
      }
    }
  },

  created: function() {
    this.fetchAccount()
    this.fetchTrendsVideos('daily')
    this.fetchTrendsVideos('weekly')
    this.fetchTrendsVideos('monthly')
  },

  methods: {
    async fetchAccount () {
      const docRef = collection(DB, 'accounts')
      const q = query(
        docRef,
        orderBy('followerCount', 'desc'),
        limit(6)
      )
      // const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(q)
      this.currentVisible = querySnapshot.docs[0]
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]

      const next = query(
        docRef,
        orderBy('followerCount', 'desc'),
        startAfter(this.lastVisible),
        limit(6)
      )

      let i = 0
      querySnapshot.forEach((doc) => {
        this.accounts.push(doc.data())
        this.videoUrls.push('')
        i++
      })
    },

    async fetchNextAccount () {
      this.currentPage ++
      this.accounts = []

      const docRef = collection(DB, 'accounts')
      const next = query(
        docRef,
        orderBy('followerCount', 'desc'),
        startAfter(this.lastVisible),
        limit(6)
      )
      // const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(next)
      this.currentVisible = querySnapshot.docs[0]
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
      
      let i = 0
      querySnapshot.forEach((doc) => {
        this.accounts.push(doc.data())
        // this.fetchVideos(doc.id, doc.data()['id'], i)
        i++
      })
    },

    async fetchLastAccount () {
      this.currentPage--
      this.accounts = []

      const docRef = collection(DB, 'accounts')
      const last = query(
        docRef,
        orderBy('followerCount', 'desc'),
        endBefore(this.currentVisible),
        limitToLast(12)
      )
      // const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(last)
      this.currentVisible = querySnapshot.docs[0]
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
      let i = 0
      querySnapshot.forEach((doc) => {
        this.accounts.push(doc.data())
        // this.fetchVideos(doc.id, doc.data()['id'], i)
        i++
      })
    },

    async fetchVideos (accountId: string, account: string, i: number) {
      const docRef = collection(DB, 'accounts', accountId, 'videos')
      const q = query(
        docRef,
        orderBy('createDate', 'desc'),
        limit(1)
      )
      const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        this.fetchOembedImageData(account, doc.data()['videoId'], i)
        this.$set(this.accounts[i], "videoId", doc.data()['videoId']);
      })
    },

    async fetchTrendsVideos (range: string) {
      const biggestVideosArray = [] as any
      var currentDate = dayjs(new Date()).format('YYYY-MM-DD')
      // var startDate = dayjs(new Date()).subtract(beforeDay, 'd').toDate()
      const docRef = collection(DB, 'trends', currentDate, range)
      const q = query(
        docRef,
        orderBy('playCount', 'desc'),
        limit(3)
      )
      const querySnapshot = await getDocs(q)

      Promise.all([querySnapshot])
      .then((values) => {
        values[0].forEach((doc: any) => {
          biggestVideosArray.push(doc.data())
        })
      })
      .then(() => {
        if (range == 'daily'){
          this.dailyTrendsArray = biggestVideosArray.sort(function(a: any, b: any) {
            return (a['playCount'] > b['playCount']) ? -1 : 1
          })
        }
        if (range == 'weekly'){
          this.weeklyTrendsArray = biggestVideosArray.sort(function(a: any, b: any) {
            return (a['playCount'] > b['playCount']) ? -1 : 1
          })
        }
        if (range == 'monthly'){
          this.monthlyTrendsArray = biggestVideosArray.sort(function(a: any, b: any) {
            return (a['playCount'] > b['playCount']) ? -1 : 1
          })
        }
      })
    },

    async onInput() {
      this.accounts = []
      const searchResult = await indexAccount.search(this.searchWord, { hitsPerPage: 10 })
      this.accounts = searchResult.hits
    },

    async fetchOembedImageData (id: string, videoId: string, i: number) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res;
      try {
        res = await axios.get(url)
        this.videoUrls[i] = res.data['thumbnail_url']
        this.$set(this.accounts[i], "avatarImage", res.data['thumbnail_url']);
      } catch (error) {
        console.error({error});
        return;
      }
    },

    async fetchOembedVideosImageData (id: string, videoId: string) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res
      try {
        res = await axios.get(url)
        let thumbnailUrl = res.data['thumbnail_url']
        console.log(thumbnailUrl)
        return thumbnailUrl
      } catch (error) {
        console.error({error})
        return
      }
    },

    // error(id: string, videoId: string, i: number) {
    //   console.log(id, videoId, i)
    //   this.fetchVideos(id, videoId, i)
    // },

    toAccountPage(accountId: string, account: string) {
      const paramsId = `${accountId}&${account}`
      let resolvedRoute = this.$router.resolve({
        name: 'Account',
        params: { id: paramsId }
      });
      console.log(resolvedRoute.href)
      window.open(resolvedRoute.href, '_blank');
    },

    toVideoTagPage(categoryId: string) {
      let resolvedRoute = this.$router.resolve({
        name: 'VideoTag',
        params: { id: categoryId }
      });
      window.open(resolvedRoute.href, '_blank');
    },

    toTrendVideoListPage(range: string) {
      let resolvedRoute = this.$router.resolve({
        name: 'TrendVideoList',
        params: { id: range }
      });
      window.open(resolvedRoute.href, '_blank');
    }
  }
})
