



















































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import axios from 'axios'
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, limit, limitToLast, startAfter, endBefore, DocumentReference, QuerySnapshot } from "firebase/firestore"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('Etc/GMT')

export default Vue.extend({
  name: 'TrendsVideo',

  props: ['trendsArray', 'label', 'color'],

  data: () => ({
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
  }),

  computed: {
    publishedAt: function () {
      return (dateTime: Date) => {
        const from = new Date(dateTime)
        const diff = new Date().getTime() - from.getTime()
        const elapsed = new Date(diff)
        if (elapsed.getUTCFullYear() - 1970 ) {
          return elapsed.getUTCFullYear() - 1970 + '年前'
        } else if (elapsed.getUTCMonth()) {
          return elapsed.getUTCMonth() + 'ヶ月前'
        } else if (elapsed.getUTCDate() - 1) {
          return elapsed.getUTCDate() - 1 + '日前'
        } else if (elapsed.getUTCHours()) {
          return elapsed.getUTCHours() + '時間前'
        } else if (elapsed.getUTCMinutes()) {
          return elapsed.getUTCMinutes() + '分前'
        } else {
          return elapsed.getUTCSeconds() + 'たった今'
        }
      }
    },
  },

  // created: function() {
  // },

  methods: {
    async fetchOembedImageData (id: string, videoId: string, i: number) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res;
      try {
        res = await axios.get(url)
        this.$set(this.trendsArray[i], "videoCover", res.data['thumbnail_url']);
      } catch (error) {
        console.error({error});
        return;
      }
    },

    // async fetchOembedVideosImageData (id: string, videoId: string) {
    //   const url = `${this.baseUrl}${id}/video/${videoId}`;
    //   let res
    //   try {
    //     res = await axios.get(url)
    //     let thumbnailUrl = res.data['thumbnail_url']
    //     console.log(thumbnailUrl)
    //     return thumbnailUrl
    //   } catch (error) {
    //     console.error({error})
    //     return
    //   }
    // },

    toAccountPage(accountId: string, account: string) {
      const paramsId = `${accountId}&${account}`
      let resolvedRoute = this.$router.resolve({
        name: 'Account',
        params: { id: paramsId }
      });
      window.open(resolvedRoute.href, '_blank');
    },

    toVideoTagPage(categoryId: string) {
      let resolvedRoute = this.$router.resolve({
        name: 'VideoTag',
        params: { id: categoryId }
      });
      window.open(resolvedRoute.href, '_blank');
    }
  }
})
