















































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import axios from 'axios';
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, limit, startAfter } from "firebase/firestore"

export default Vue.extend({
  name: 'VideoTag',


  data: () => ({
    account: {} as any,
    videos: [] as any,
    lastVisible: {} as any,
    accountId: '',
    categoryId: '',
    hashtag: '',
    tags: [] as any,
    image: '',
    images: [],
    imageError: false,
    totalPlayCount: 0,
    totalDuration: 0,
    totalCommentLikeCount: 0,
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
    currentIndex: 'createDate',
    orderBy: [
      {
        name: '新着順',
        index: 'createDate'
      },
      {
        name: '視聴回数順',
        index: 'playCount'
      },
      {
        name: 'いいね数順',
        index: 'heart'
      }
    ]
  }),

  computed: {
    publishedAt: function () {
      return (dateTime: Date) => {
        const from = new Date(dateTime)
        const diff = new Date().getTime() - from.getTime()
        const elapsed = new Date(diff)
        if (elapsed.getUTCFullYear() - 1970 ) {
          return elapsed.getUTCFullYear() - 1970 + '年前'
        } else if (elapsed.getUTCMonth()) {
          return elapsed.getUTCMonth() + 'ヶ月前'
        } else if (elapsed.getUTCDate() - 1) {
          return elapsed.getUTCDate() - 1 + '日前'
        } else if (elapsed.getUTCHours()) {
          return elapsed.getUTCHours() + '時間前'
        } else if (elapsed.getUTCMinutes()) {
          return elapsed.getUTCMinutes() + '分前'
        } else {
          return elapsed.getUTCSeconds() + 'たった今'
        }
      }
    },

    convertTimeformat: function () {
      return (time: number) => {
        // const sec = Math.floor(time/1000) % 60
        // const min = Math.floor(time/1000/60) % 60
        const hours = Math.floor(time/1000/60/60)
        const formatTime = `${hours.toLocaleString('ja-JP')}時間`
        return formatTime
      }
    },

    completionRate: function () {
      return (perDuration: number, duration: number) => {
        return perDuration / duration * 100
      }
    }
  },

   watch: {
    videos: {
      handler: function(newVal, oldVal){
        if(this.imageError) {
          this.account['avatarImage'] = newVal[0]['videoImageUrl']
        }
      },
      deep: true
    }
  },

  created: function() {
    this.categoryId = this.$route.params.id
    this.fetchVideos('createDate')
  },

  methods: {

    async fetchCategoryWord(categoryId: string) {
      const docRef = doc(DB, 'categories', categoryId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.tags = docSnap.data()['tags']
        this.hashtag = docSnap.data()['label']
      } else {
        console.log("No such document!")
      }
    },

    async fetchVideos (index: string) {
      await this.fetchCategoryWord(this.categoryId)
      const docRef = collection(DB, 'videos')
      const q = query(
        docRef,
        where('tags', 'array-contains-any', this.tags),
        orderBy(index, 'desc'),
        limit(6)
      )
      const querySnapshot = await getDocs(q)
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
      let i = 0
      querySnapshot.forEach((doc) => {
        this.fetchOembedImageData(this.account, doc.data()['videoId'], i)
        this.videos.push(doc.data())
        i++
      })
    },

    async fetchNextVideos (index: string) {
      const docRef = collection(DB, 'videos')
      const next = query(
        docRef,
        where('tags', 'array-contains-any', this.tags),
        orderBy(index, 'desc'),
        startAfter(this.lastVisible),
        limit(6)
      )
      
      const querySnapshot = await getDocs(next)
      this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1]
      let i = 0
      querySnapshot.forEach((doc) => {
        this.fetchOembedImageData(this.account, doc.data()['videoId'], i)
        this.videos.push(doc.data())
        i++
      })
    },

    async fetchAccount (accountId: string) {
      const docRef = doc(DB, 'accounts', accountId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.account = docSnap.data()
      } else {
        console.log("No such document!")
      }
    },

    async fetchOembedImageData (id: string, videoId: string, i: number) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res
      try {
        res = await axios.get(url)
        let thumbnailUrl = res.data['thumbnail_url']
        this.videos.forEach((e:any, index: number) => {
          if(e.videoId == videoId) {
            this.$set(this.videos[index], "videoImageUrl", thumbnailUrl)
          }
        });
        return res.data
      } catch (error) {
        console.error({error})
        return
      }
    },

    changeIndex(index: string) {
      this.videos = []
      this.currentIndex = index
      this.fetchVideos(index)
    },

    toVideoInsight(accountId: string, videoId: string, account: string) {
      const paramsId = `${accountId}&${videoId}&${account}`
      let resolvedRoute = this.$router.resolve({
        name: 'VideoAnalytics',
        params: { id: paramsId }
      });
      window.open(resolvedRoute.href, '_blank');
    },

    toTiktokPage(videoId: string, account: string) {
      const youtubelink = `https://www.tiktok.com/@${account}/video/${videoId}`
      window.open(youtubelink, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    errorImage () {
      this.imageError = true
    }
  }
})
