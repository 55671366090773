














































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import BarChart from '../components/BarChart.vue'
import axios from 'axios';
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, limit, startAt, endAt } from "firebase/firestore"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

export interface ChartData {
  'label': string
  'barDatas': number[]
  'lineDatas': number[]
}
export default Vue.extend({
  name: 'AccountAnalytics',

  components: {
    BarChart,
  },

  data: () => ({
    accountType: '',
    videos: [] as any,
    sortedVideos: [] as any,
    labels: [] as any,
    accountId: '',
    dates: [dayjs(new Date()).format('YYYY-MM-DD'), dayjs(new Date()).subtract(30, 'd').format('YYYY-MM-DD')],
    menu: false,
    playCount: {
      label: '総再生数',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    heartCount: {
      label: 'いいね',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    followerCount: {
      label: 'フォロワー',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    followingCount: {
      label: 'フォロー',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
    videoUrls: [] as any,
    currentIndex: 'createDate',
    currentLabel: '前週比',
    orderBy: [
      // {
      //   label: '前日比',
      //   index: 'dayBefore'
      // },
      {
        label: '前週比',
        index: 'weekBefore'
      },
      {
        label: '前月比',
        index: 'monthBefore'
      }
    ]
  }),

  created: function() {
    this.accountId = (this as any).$route.params.id
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetchDailyAccountData(this.accountId)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetchAccountVideos(this.accountId)
    window.scrollTo({
      top: 0
    })
  },

  computed: {
    minDate: function() {
      const formatDate = '2021-10-02'
      return formatDate
    },

    maxDate: function() {
      let date = new Date()
      const formatDate = dayjs(date).format('YYYY-MM-DD')
      return formatDate
    },

    convertDateformat: function () {
      return (date: Date) => {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const formatDate = `${month}/${day}`
        return formatDate
      }
    },

    chartData: function () {
      return (data: ChartData, labels: string[]) => {
        const d = {
          labels: labels,
          datasets: [
            {
              label: data['label'],
              data: data['barDatas'],
              borderColor: 'rgba(255, 99, 132, 1)',
              fill: false,
              type: 'line',
              lineTension: 0.3,
              yAxisID: "y_1"
            },
            {
              label: '前日比',
              data: (this as any).generateLineDatas(data['barDatas']),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              yAxisID: "y_2"
            }
          ]
        }
        return d
      }
    },

    options: function () {
      const options = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem: any, data: any){
              return tooltipItem.yLabel.toLocaleString('ja-JP')
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true
            }
          }],
          yAxes: [{
            id: "y_1",
            position: "left",
            ticks: {
              beginAtZero: true,
              callback: function(label: any, index: any, labels: any) {
                  return label.toLocaleString('ja-JP')
              }
            }
          },
          {
            id: "y_2",
            position: "right",
            ticks: {
              beginAtZero: true,
              callback: function(label: any, index: any, labels: any) {
                  return label.toLocaleString('ja-JP')
              }
            }
          }]
        }
      }
      return options
    },

    generateLineDatas: function () {
      return (dataArray: number[]) => {
        let d = [0]
        dataArray.forEach((e,i) => {
          if (i > 0) {
            d.push(e - dataArray[i-1])
          }
        })
        return d
      }
    },

    dateRangeText () {
      const sortedDateRange = this.dates.slice().sort(function(a, b){
        return (a > b ? 1 : -1);
      })
      return sortedDateRange.join(' ~ ')
    }
  },

  methods: {

    async fetchDailyAccountData (accountId: string) {
      (this as any).initData()
      const sortedDateRange = this.dates.slice().sort(function(a, b){
        return (a > b ? 1 : -1);
      })
      var endDate = dayjs(sortedDateRange[1]).toDate()
      var startDate = dayjs(sortedDateRange[0]).toDate()
      this.dates = sortedDateRange
      const docRef = collection(DB, 'accounts', accountId, 'date')
      const q = query(
        docRef,
        orderBy('getDate', 'asc'),
        startAt(startDate),
        endAt(endDate),
      )
      const docSnap = await getDocs(docRef)

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let data: any = doc.data()
        // let date = data['getDate'].toDate()
        let date = new Date(Number(doc.id))
        const formatDate = dayjs(date).format('MM/DD')
        if (this.labels.includes(formatDate)) {
          this.labels.pop()
          this.heartCount['barDatas'].pop()
          this.followerCount['barDatas'].pop()
          this.followingCount['barDatas'].pop()
        }
        this.labels.push(formatDate)
        this.heartCount['barDatas'].push(data['heart'])
        this.followerCount['barDatas'].push(data['followerCount'])
        this.followingCount['barDatas'].push(data['followingCount'])
      })
    },

    initData() {
      this.menu = false
      this.labels = []
      this.playCount['barDatas'] = []
      this.playCount['lineDatas'] = []
      this.heartCount['barDatas'] = []
      this.heartCount['lineDatas'] = []
      this.followerCount['barDatas'] = []
      this.followerCount['lineDatas'] = []
      this.followingCount['barDatas'] = []
      this.followingCount['lineDatas'] = []
    },

    async fetchAccountVideos (accountId: string) {
      const docRef = collection(DB, 'accounts', accountId, 'videos')
      const q = query(
        docRef,
        orderBy('createDate', 'desc'),
        limit(100)
      )
      const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        this.videos.push(doc.data())
      })

      await (this as any).fetchAccountVideosDayBefore(accountId, 7)
    },

    async fetchAccountVideosDayBefore (accountId: string, dayCount: number) {
      var endDate = new Date()
      var startDate = dayjs(new Date()).subtract(dayCount, 'd').toDate()
      const videoDataArray = [] as any

      Promise.all(this.videos.map(async (e: any) => {
        const docRef = collection(DB, 'accounts', accountId, 'videos', e['videoId'], 'date')
        const q = query(
          docRef,
          orderBy('getDate', 'asc'),
          startAt(startDate),
          endAt(endDate),
          limit(1)
        )
        const querySnapshot = await getDocs(q)

        querySnapshot.forEach((doc) => {
          let i = this.videos.findIndex(( e: any ) => e['videoId'] === doc.data()['videoId'])
          e['dayBefore'] = doc.data()['playCount']
          e['differencePlayCountOfDay'] = e['playCount'] - doc.data()['playCount']
          videoDataArray.push(e)
          // console.log(i, e['differencePlayCountOfDay'])
        })
      }))
      .then(()=> {
        this.sortedVideos = videoDataArray.sort(function(a: any, b: any) {
          return (a['differencePlayCountOfDay'] > b['differencePlayCountOfDay']) ? -1 : 1
        })
      })
      .catch( ( reason ) => {
        console.log( reason )
      })
    },

    async fetchOembedImageData (id: string, videoId: string, i: number) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res;
      try {
        res = await axios.get(url)
        this.sortedVideos[i]['videoCover'] = res.data['thumbnail_url']
      } catch (error) {
        console.error({error});
        return;
      }
    },

    changeIndex(index: string, label: string) {
      this.sortedVideos = []
      this.currentIndex = index
      this.currentLabel = label
      if(index == 'dayBefore') {
        (this as any).fetchAccountVideosDayBefore(this.accountId, 1)
      }
      if(index == 'weekBefore') {
        (this as any).fetchAccountVideosDayBefore(this.accountId, 7)
      }
      if(index == 'monthBefore') {
        (this as any).fetchAccountVideosDayBefore(this.accountId, 30)
      }
    },

    toVideoInsight(accountId: string, videoId: string, account: string) {
      const paramsId = `${accountId}&${videoId}&${account}`
      let resolvedRoute = this.$router.resolve({
        name: 'VideoAnalytics',
        params: { id: paramsId }
      })
      window.open(resolvedRoute.href, '_blank')
    },
  }
})
