import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Account from '../views/Account.vue'
import AccountAnalytics from '../views/AccountAnalytics.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import TiktokLogin from '../views/TiktokLogin.vue'
import PremiumAccountAnalytics from '../views/PremiumAccountAnalytics.vue'
import TrendVideoList from '../views/TrendVideoList.vue'
import VideoAnalytics from '../views/VideoAnalytics.vue'
import VideoTag from '../views/VideoTag.vue'
import Signup from '../views/Signup.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/tiktoklogin',
    name: 'TiktokLogin',
    component: TiktokLogin
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: Account,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/accountanalytics/:id',
    name: 'AccountAnalytics',
    component: AccountAnalytics,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/premiumaccountanalytics/:id',
    name: 'PremiumAccountAnalytics',
    component: PremiumAccountAnalytics,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/videoanalytics/:id',
    name: 'VideoAnalytics',
    component: VideoAnalytics,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/videotag/:id',
    name: 'VideoTag',
    component: VideoTag,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/trendvideolist/:id',
    name: 'TrendVideoList',
    component: TrendVideoList,
    props: (route) => ({id: String(route.params.id)})
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
