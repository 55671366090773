






import Vue from 'vue'
import TrendsVideo from '../components/TrendsVideo.vue'
import { DB } from '@/firebase/db'
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, limit, limitToLast, startAfter, endBefore, DocumentReference, QuerySnapshot } from "firebase/firestore"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('Etc/GMT')

export default Vue.extend({
  name: 'Home',

  components: {
    TrendsVideo,
  },

  data: () => ({
    accounts: [] as any,
    trendsDataArray: [] as any,
    range: '',
    label: '',
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
  }),

  computed: {
    convertTimeformat: function () {
      return (time: number) => {
        // const sec = Math.floor(time/1000) % 60
        // const min = Math.floor(time/1000/60) % 60
        const hours = Math.floor(time/1000/60/60)
        const formatTime = `${hours.toLocaleString('ja-JP')}時間`
        return formatTime
      }
    }
  },

  created: function() {
    this.range = this.$route.params.id
    this.changeLabel(this.range)
    this.fetchTrendsVideos(this.range)
  },

  methods: {

    async fetchTrendsVideos (range: string) {
      const biggestVideosArray = [] as any
      var currentDate = dayjs(new Date()).format('YYYY-MM-DD')
      console.log(currentDate)
      const docRef = collection(DB, 'trends', currentDate, range)
      const q = query(
        docRef,
        orderBy('playCount', 'desc'),
        limit(100)
      )
      const querySnapshot = await getDocs(q)

      Promise.all([querySnapshot])
      .then((values) => {
        values[0].forEach((doc: any) => {
          biggestVideosArray.push(doc.data())
        })
      })
      .then(() => {
        this.trendsDataArray = biggestVideosArray.sort(function(a: any, b: any) {
          return (a['playCount'] > b['playCount']) ? -1 : 1
        })
      })
    },

    changeLabel(label: string) {
      if(label == 'daily') {
        this.label = '急上昇トレンド動画'
      }
      if(label == 'weekly') {
        this.label = '今週のトレンド動画'
      }
      if(label == 'monthly') {
        this.label = '今月のトレンド動画'
      }
    }
  }
})
