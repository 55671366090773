<script>
import { Bar, mixins } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'barchart',
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],

  mounted: function () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>