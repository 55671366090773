



























import Vue from 'vue'
import firebase from 'firebase/compat/app'
import { DB } from '@/firebase/db'
import { Auth } from '@/firebase/auth'
import axios from 'axios'
import { getFirestore, collection, setDoc, where, getDoc, getDocs, doc } from "firebase/firestore"
import * as firebaseui from 'firebaseui/esm__ja'
import 'firebaseui/dist/firebaseui.css'

export default Vue.extend ({
  name: "Tiktoklogin",

  data: () => ({
  }),

  mounted: function() {
    this.login()
    this.test()
    this.checkLoginState()
  },

  methods: {
    login () {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
      const getDestination = localStorage.getItem('destination')
      const query = this.$route.query
      const params: any = { 
        'Content-Type': 'application/json',
        'access_token': 'act.1a4b9d05d9ad1a56294b93b5609cdfbdNzQgbpbUWyFNvhC9QqIvKEjuuPHn',
        'open_id': '723f24d7-e717-40f8-a2b6-cb8464cd23b4',
        'fields': ['open_id', 'union_id', 'avatar_url']
      }
      let uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function(authResult: any, redirectUrl: string) {
            let url_access_token = 'https://open-api.tiktok.com/oauth/access_token/';
            url_access_token += '?client_key=' + process.env.VUE_APP_TIKTOK_CLIENT_KEY
            url_access_token += '&client_secret=' + process.env.VUE_APP_TIKTOK_CLIENT_SECRET;
            url_access_token += '&code=' + query.code
            url_access_token += '&grant_type=authorization_code';
            const params: any = {
              'Content-Type': 'application/json',
              'access_token': 'act.1a4b9d05d9ad1a56294b93b5609cdfbdNzQgbpbUWyFNvhC9QqIvKEjuuPHn',
              'open_id': '723f24d7-e717-40f8-a2b6-cb8464cd23b4',
              'fields': ['open_id', 'union_id', 'avatar_url']
            }
            const getUserinfo = axios.post(url_access_token)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });

            // const docRef = doc(DB, 'users', authResult.user.uid)
            // setDoc(docRef, {
            //   uid: authResult.user.uid,
            //   email: authResult.user.email,
            //   tiktokParams: query
            // });
            return true;
          },
          uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
          }
        },
        // signInSuccessUrl: getDestination,
        signInOptions: [
          { 
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
          }
        ],
      // tosUrl: '/kiyaku',           // 規約とかを書いたページ
      // privacyPolicyUrl: '/policy'  // プライバシーポリシーを書いたページ
      }
      Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      ui.start('#firebaseui-auth-container', uiConfig);
    },

    checkLoginState () {
      Auth.onAuthStateChanged( (user) => {
        if (user) {
          const uid = user.uid;
        } else { 
          console.log("none")
        }
      })
    },

    test () {
      const code = this.$route.query.code
      console.log(this.$route.query)
      let url_access_token = 'https://open-api.tiktok.com/oauth/access_token/';
      url_access_token += '?client_key=' + process.env.VUE_APP_TIKTOK_CLIENT_KEY
      url_access_token += '&client_secret=' + process.env.VUE_APP_TIKTOK_CLIENT_SECRET;
      url_access_token += '&code=' + code;
      url_access_token += '&grant_type=authorization_code';
      const params: any = { 
        'Content-Type': 'application/json',
        'access_token': 'act.1a4b9d05d9ad1a56294b93b5609cdfbdNzQgbpbUWyFNvhC9QqIvKEjuuPHn',
        'open_id': '723f24d7-e717-40f8-a2b6-cb8464cd23b4',
        'fields': ['open_id', 'union_id', 'avatar_url']
      }
      const getUserinfo = axios.post(url_access_token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  },
})
