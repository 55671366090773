

















































import Vue from 'vue'

export default Vue.extend({
  name: 'LoginDialog',

  data: () => ({
    dialog: false,
  }),

  methods: {
    toLogin() {
      this.dialog = false
      const currentUrl = this.$route.fullPath
      localStorage.setItem('destination', currentUrl)
      this.$router.push({ name: 'Login' })
    }
  }
})
