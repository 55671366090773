









import Vue from 'vue';
import Header from './components/Header.vue'

export default Vue.extend({
  name: 'App',

  components: {
    Header
  },

  data: () => ({
    //
  }),
  methods: {
    toHome() {
      this.$router.push({ name: 'Home' })
    }
  }
});
