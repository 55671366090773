import { Credentials } from './types/credentials'

export const credentials: Credentials = {
  // config: {
  //   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  //   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  //   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  //   databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  //   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  //   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
  // }
  config: {
    apiKey: "AIzaSyCX_NWb2FXryzDa3TEQhVMlMBd7Bbwpte0",
    authDomain: "tiktok-analytics-bb3c5.firebaseapp.com",
    storageBucket: "tiktok-analytics-bb3c5.appspot.com",
    databaseURL: "https://tiktok-analytics-bb3c5.firebaseio.com",
    projectId: "tiktok-analytics-bb3c5",
    messagingSenderId: "654968110263"
  }
}