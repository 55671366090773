
























import Vue from 'vue'
import firebase from 'firebase/compat/app'
import { Auth } from '@/firebase/auth'
import * as firebaseui from 'firebaseui/esm__ja'
import 'firebaseui/dist/firebaseui.css'

export default Vue.extend ({
  name: "Login",

  data: () => ({
  }),

  mounted: function() {
    this.login()
    this.checkLoginState()
  },

  methods: {
    login () {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
      const getDestination = localStorage.getItem('destination')
      let uiConfig = {
        signInSuccessUrl: getDestination,
        signInOptions: [
          { 
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
          },
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.TwitterAuthProvider.PROVIDER_ID
        ],
      // tosUrl: '/kiyaku',           // 規約とかを書いたページ
      // privacyPolicyUrl: '/policy'  // プライバシーポリシーを書いたページ
      }
      Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      ui.start('#firebaseui-auth-container', uiConfig);
    },

    checkLoginState () {
      Auth.onAuthStateChanged( (user) => {
        if (user) {
          const uid = user.uid;
        } else { 
          console.log("none")
        }
      })
    }
  },
})
