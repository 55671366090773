


































import Vue from 'vue'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import { Auth } from '@/firebase/auth'
import * as firebaseui from 'firebaseui/esm__ja'
import 'firebaseui/dist/firebaseui.css'

export default Vue.extend ({
  name: "Signup",

  data: () => ({
  }),

  mounted: function() {
    this.checkLoginState()
  },

  methods: {
    signup () {
      const CLIENT_KEY = process.env.VUE_APP_TIKTOK_CLIENT_KEY // this value can be found in app's developer portal
      const redirect_uri = 'https://ttstudio.jp/tiktoklogin/'
      const csrfState = Math.random().toString(36).substring(7);

      let url = `https://open-api.tiktok.com/platform/oauth/connect/`;

      url += `?client_key=${CLIENT_KEY}`;
      url += `&scope=user.info.basic,video.list`;
      url += `&response_type=code`;
      url += `&redirect_uri=${redirect_uri}`;
      url += `&state=` + csrfState;

      window.location.href = url
    },

    checkLoginState () {
      Auth.onAuthStateChanged( (user) => {
        if (user) {
          const uid = user.uid;
        } else { 
          console.log("none")
        }
      })
    }
  },
})
