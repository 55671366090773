








































































































































































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import axios from 'axios';
import BarChart from '../components/BarChart.vue'
import { getFirestore, collection, query, where, getDoc, getDocs, doc, orderBy, startAt, endAt } from "firebase/firestore"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('Etc/GMT')

export interface ChartData {
  'label': string
  'barDatas': number[]
  'lineDatas': number[]
}
export default Vue.extend({
  name: 'VideoAnalytics',

  components: {
    BarChart,
  },

  data: () => ({
    video: {} as any,
    labels: [] as any,
    insightLabels: [] as any,
    commentLikeCountLabels: [] as any,
    account: {} as any,
    accountId: '',
    videoId: '',
    dates: [dayjs(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), dayjs(new Date()).add(1, 'd').format('YYYY-MM-DD')],
    menu: false,
    analyticsData: [] as any,
    heartCount: {
      label: 'いいね',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    playCount: {
      label: '再生数',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    shareCount: {
      label: 'シェア数',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    commentCount: {
      label: 'コメント数',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    videoTotalDuration: {
      label: 'トータル再生時間',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    videoPerDuration: {
      label: '平均再生時間',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    videoUv: {
      label: 'ユニークリーチ数',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    commentLikeCount: {
      label: 'コメント内いいね',
      barDatas: [] as any,
      lineDatas: [] as any
    },
    videoPagePerForYou: {
      label: 'おすすめ流入',
      barDatas: [] as any,
      lineDatas: [] as any,
      key: "For You"
    },
    videoPagePerFollow: {
      label: 'フォロー流入',
      barDatas: [] as any,
      lineDatas: [] as any,
      key: "Follow"
    },
    videoPagePerProfile: {
      label: 'プロフィール流入',
      barDatas: [] as any,
      lineDatas: [] as any,
      key: "Personal Profile"
    },
    baseUrl: 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@',
    oembedData: {} as any,
    currnetSortTime: 'daily',
    orderBy: [
      {
        name: '日毎',
        index: 'daily'
      },
      {
        name: '時間毎',
        index: 'hourly'
      }
    ]
  }),

  watch: {
    video: {
      handler: function(newVal, oldVal){
        const formatDate = dayjs(new Date(newVal['createDate'].toDate())).format('YYYY-MM-DD')
        this.$set(this.dates, 0, formatDate)
      },
      deep: true
    }
  },

  created: function() {
    this.accountId = this.$route.params.id.split('&')[0]
    this.videoId = this.$route.params.id.split('&')[1]
    this.account = this.$route.params.id.split('&')[2]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetchDailyAllVideos(this.accountId, this.videoId)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetchVideo(this.accountId, this.videoId)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fetchAccount(this.accountId)
    // this.fetchCommentLikeCountData(this.accountId, this.videoId)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.getOembedData(this.account, this.videoId)
    window.scrollTo({
      top: 0
    })
  },

  computed: {

    minDate: function() {
      let date = new Date(this.video['createDate'].toDate())
      const formatDate = dayjs(date).format('YYYY-MM-DD')
      return formatDate
    },

    maxDate: function() {
      let date = new Date()
      const formatDate = dayjs(date).format('YYYY-MM-DD')
      return formatDate
    },

    convertArrayToString: function() {
      return (array: string[]) => {
        return array.join(' #')
      }
    },

    chartData: function () {
      return (data: ChartData, labels: string[]) => {
        const d = {
          labels: labels,
          datasets: [
            {
              label: data['label'],
              data: data['lineDatas'],
              borderColor: 'rgba(255, 99, 132, 1)',
              fill: false,
              type: 'line',
              lineTension: 0.3,
              yAxisID: "y_1"
            },
            {
              label: '前日比',
              data: (this as any).generateLineDatas(data['lineDatas']),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              fill: false,
              type: 'bar',
              yAxisID: "y_2"
            }
            // {
            //   label: '前日比',
            //   data: (this as any).generateLineDatas(data['lineDatas']),
            //   backgroundColor: 'rgba(54, 162, 235, 0.2)',
            //   borderColor: 'rgba(54, 162, 235, 1)',
            //   borderWidth: 1,
            //   fill: false,
            //   type: 'bar',
            //   yAxisID: "y_2"
            // }
          ]
        }
        return d
      }
    },

    options: function () {
      const options = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem: any, data: any){
              return tooltipItem.yLabel.toLocaleString('ja-JP')
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true
            }
          }],
          yAxes: [
            {
              id: "y_1",
              position: "left",
              ticks: {
                beginAtZero: true,
                callback: function(label: any, index: any, labels: any) {
                    return label.toLocaleString('ja-JP')
                }
              }
            },
            {
              id: "y_2",
              position: "right",
              ticks: {
                beginAtZero: true,
                callback: function(label: any, index: any, labels: any) {
                    return label.toLocaleString('ja-JP')
                }
              }
            }
          ]
        }
      }
      return options
    },

    timeOptions: function () {
      const options = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem: any, data: any){
              const hours = Math.floor(tooltipItem.yLabel/1000/60/60)+'時間'
              return hours
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Month'
            }
          }],
          yAxes: [{
            id: "y_1",
            position: "left",
            ticks: {
              beginAtZero: true,
              callback: function(label: any, index: any, labels: any) {
                  // const sec = Math.floor(label/1000) % 60
                  // const min = Math.floor(label/1000/60) % 60
                  const hours = Math.floor(label/1000/60/60)
                  // const formatTime = `${hours}:${min}:${sec}`
                  return hours
              }
            }
          },
          {
            id: "y_2",
            position: "right",
            ticks: {
              beginAtZero: true,
                allback: function(label: any, index: any, labels: any) {
                // const sec = Math.floor(label/1000) % 60
                // const min = Math.floor(label/1000/60) % 60
                const hours = Math.floor(label/1000/60/60)
                // const formatTime = `${hours}:${min}:${sec}`
                return hours
              }
            }
          }]
        }
      }
      return options
    },

    secTimeOptions: function () {
      const options = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem: any, data: any){
              const sec = Math.floor(tooltipItem.yLabel/1000)+'秒'
              return sec
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Month'
            }
          }],
          yAxes: [{
            id: "y_1",
            position: "left",
            ticks: {
              beginAtZero: true,
              callback: function(label: any, index: any, labels: any) {
                  const sec = Math.floor(label/1000)
                  return sec
              }
            }
          },
          {
            id: "y_2",
            position: "right",
            ticks: {
              beginAtZero: true,
              callback: function(label: any, index: any, labels: any) {
                  const sec = Math.floor(label/1000)
                  return sec
              }
            }
          }]
        }
      }
      return options
    },

    generateLineDatas: function () {
      return (dataArray: number[]) => {
        let d = [0]
        dataArray.forEach((e,i) => {
          if (i > 0) {
            d.push(e - dataArray[i-1])
          }
        })
        return d
      }
    },

    completionRate: function () {
      return (perDuration: number, duration: number) => {
        return perDuration / duration * 100
      }
    },

    dateRangeText () {
      const sortedDateRange = this.dates.slice().sort(function(a, b){
        return (a > b ? 1 : -1);
      })
      return sortedDateRange.join(' ~ ')
    }


  },

  methods: {
    async fetchVideo (accountId: string, videoId: string) {
      const docRef = doc(DB, 'accounts', accountId, 'videos', videoId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.video = docSnap.data()
      } else {
        console.log("No such document!")
      }
    },

    async fetchDailyAllVideos (accountId: string, videoId: string) {
      (this as any).initAnalyticsData()
      const sortedDateRange = this.dates.slice().sort(function(a, b){
        return (a > b ? 1 : -1);
      })
      var endDate = dayjs(sortedDateRange[1]).toDate()
      var startDate = dayjs(sortedDateRange[0]).toDate()
      const docRef = collection(DB, 'accounts', accountId, 'videos', videoId, 'date')
      const q = query(
        docRef,
        orderBy('getDate', 'asc'),
        startAt(startDate),
        endAt(endDate),
      )
      const docSnap = await getDocs(docRef)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let data: any = doc.data()
        // let date = data['getDate'].toDate()
        let date = new Date(Number(doc.id))
        const formatDate = dayjs(date).format('MM/DD')
        if (this.labels.includes(formatDate)) {
          this.labels.pop()
          this.heartCount['lineDatas'].pop()
          this.playCount['lineDatas'].pop()
          this.shareCount['lineDatas'].pop()
          this.commentCount['lineDatas'].pop()
        }

        if (this.insightLabels.includes(formatDate)) {
          this.insightLabels.pop()
          this.videoTotalDuration['lineDatas'].pop()
          this.videoPerDuration['lineDatas'].pop()
          this.videoUv['lineDatas'].pop()
          this.videoPagePerForYou['lineDatas'].pop()
          this.videoPagePerFollow['lineDatas'].pop()
          this.videoPagePerProfile['lineDatas'].pop()
        }

        this.labels.push(formatDate)
        this.heartCount['lineDatas'].push(data['heart'])
        this.playCount['lineDatas'].push(data['playCount'])
        this.shareCount['lineDatas'].push(data['shareCount'])
        this.commentCount['lineDatas'].push(data['commentCount'])

        if (data['videoPagePercent'] && data['videoPagePercent'].length > 0) {
          this.insightLabels.push(formatDate)
          this.videoTotalDuration['lineDatas'].push(data['videoTotalDuration'])
          this.videoPerDuration['lineDatas'].push(data['videoPerDuration'])
          this.videoUv['lineDatas'].push(data['videoUv'])

          data['videoPagePercent'].forEach((e: any) => {
            if (e['key'] == 'For You') {
              this.videoPagePerForYou['lineDatas'].push(e['value']*100)
            }
            if (e['key'] == 'Follow') {
              this.videoPagePerFollow['lineDatas'].push(e['value']*100)
            }
            if (e['key'] == 'Personal Profile') {
              this.videoPagePerProfile['lineDatas'].push(e['value']*100)
            }
          })
        }
      })
    },

    async fetchHourlyAllVideos (accountId: string, videoId: string) {
      (this as any).initAnalyticsData()
      const sortedDateRange = this.dates.slice().sort(function(a: any, b: any){
        return (a > b ? 1 : -1);
      })
      var endDate = dayjs(sortedDateRange[1]).toDate()
      var startDate = dayjs(sortedDateRange[0]).toDate()
      // console.log(startDate,endDate )
      const docRef = collection(DB, 'accounts', accountId, 'videos', videoId, 'date')
      const q = query(
        docRef,
        orderBy('getDate', 'asc'),
        startAt(startDate),
        endAt(endDate),
        )
      const docSnap = await getDocs(docRef)

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let data: any = doc.data()
        let date = new Date(Number(doc.id))
        const formatDate = dayjs(date).hour()
        
          this.labels.push(formatDate)
          this.heartCount['lineDatas'].push(data['heart'])
          this.playCount['lineDatas'].push(data['playCount'])
          this.shareCount['lineDatas'].push(data['shareCount'])
          this.commentCount['lineDatas'].push(data['commentCount'])
        
        
          if (data['videoPagePercent'] && data['videoPagePercent'].length > 0) {
            this.insightLabels.push(formatDate)
            this.videoTotalDuration['lineDatas'].push(data['videoTotalDuration'])
            this.videoPerDuration['lineDatas'].push(data['videoPerDuration'])
            this.videoUv['lineDatas'].push(data['videoUv'])

            data['videoPagePercent'].forEach((e: any) => {
              if (e['key'] == 'For You') {
                this.videoPagePerForYou['lineDatas'].push(e['value']*100)
              }
              if (e['key'] == 'Follow') {
                this.videoPagePerFollow['lineDatas'].push(e['value']*100)
              }
              if (e['key'] == 'Personal Profile') {
                this.videoPagePerProfile['lineDatas'].push(e['value']*100)
              }
            })
          }
        
      })
    },

    initAnalyticsData () {
      this.menu = false
      this.labels = []
      this.insightLabels = []
      this.heartCount['lineDatas'] = []
      this.playCount['lineDatas'] = []
      this.shareCount['lineDatas'] = []
      this.commentCount['lineDatas'] = []
      this.videoTotalDuration['lineDatas'] = []
      this.videoPerDuration['lineDatas'] = []
      this.videoUv['lineDatas'] = []
      this.videoPagePerForYou['lineDatas'] = []
      this.videoPagePerFollow['lineDatas'] = []
      this.videoPagePerProfile['lineDatas'] = []
    },

    async fetchAccount (accountId: string) {
      const docRef = doc(DB, 'accounts', accountId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.account = docSnap.data()
      } else {
        console.log("No such document!")
      }
    },

    async fetchCommentLikeCountData (accountId: string, videoId: string) {
      const docRef = collection(DB, 'accounts', accountId, 'videos', videoId, 'comment')
      const q = query(docRef, orderBy('id', 'asc'))
      const docSnap = await getDocs(docRef)

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        let data: any = doc.data()
        const month = data['id'].split('_')[1]
        const day = data['id'].split('_')[2]
        this.commentLikeCountLabels.push(`${month}/${day}`)
        this.commentLikeCount['lineDatas'].push(data['totalCommentLikeCount'])
      })
    },

    async getOembedData(id: string, videoId: string) {
      const url = `${this.baseUrl}${id}/video/${videoId}`;
      let res;
      try {
        res = await axios.get(url);
        this.oembedData = res.data;
      } catch (error) {
        console.error({error});
        return;
      }
    },

    changeIndex(index: string) {
      this.currnetSortTime = index
      if(index == 'daily') {
        (this as any).fetchDailyAllVideos(this.accountId, this.videoId)
      }
      if(index == 'hourly') {
        (this as any).fetchHourlyAllVideos(this.accountId, this.videoId)
      }
    },

    errorImage() {
      this.account['avatarImage'] = this.oembedData['thumbnail_url']
    },

    toAccountPage(accountId: string, account: string) {
      const paramsId = `${accountId}&${account}`
      this.$router.push({ name: 'Account', params: { id: paramsId }})
    }
  }
})
